import style from "../../styles/Modal.module.scss";
import IconWrapper from "./MaterialIcon";
import { Button } from "./FormComponent";

export default function InfoModal({
  closeModal,
  info,
  extra,
  action,
  type,
  mdSize,
  children,
}) {
  return (
    <div className={style.myModal} onClick={closeModal}>
      <div
        className={`${style.innerContent} ${mdSize}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={style.times} onClick={closeModal}>
          <IconWrapper iconName={"close"} onClick={closeModal} />
        </div>
        <p className={style.main_text}>{info}</p>
        <p className={style.sub_text} style={{fontWeight: "300"}} >{extra}</p>
        {children}
        <div className={style.btn_group} style={{width: type == "warning" ? "" : type == "info" ? "" : "210px"}}>
          {type == "warning" ? null : type == "info" ? (
            <Button
              onClick={closeModal}
              btnText="Schließen"
              variant="bg-blue"
              className="mt-32"
            />
          ) : (
            <>
            <Button
              onClick={closeModal}
              btnText="Nein"
              variant="bg-outline"
              styles={{flex:1}}
            />
            <Button
              onClick={action}
              btnText="Ja"
              variant="bg-blue"
              styles={{flex:1}}
            />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
