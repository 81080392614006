import { useRef, useState, useEffect } from "react";
import { ClickAwayListener } from "@mui/base";
import IconWrapper from "./Icons";
import styles from "../../styles/reusable.module.scss";
import { Button } from "./FormComponent";;

export default function Dropdown({
  type = "button" || "icon",
  iconPrefix,
  children,
  dropdownContent = [
    {
      name: "",
      action: () => {},
    },
  ],
  style = {},
  containerClassName = "",
  iconClass,
  btnText,
  iconName,
  variant,
  iconStyle,
  containerStyle
}) {
  const [show, setShow] = useState(false);
  const btnRef = useRef();
  const [minWidth, setMinWidth] = useState(0);

  useEffect(() => {
    if (btnRef.current) {
      const width = btnRef.current.getBoundingClientRect().width;
      setMinWidth(width);
    }
  }, [show]);

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div className={`my-dropdown ${containerClassName}`} style={containerStyle}>
        {type == "button" ? (
          <Button
            ref={btnRef}
            btnText={btnText}
            iconName={iconName}
            iconClass={iconClass}
            variant={variant}
            styles={{fontWeight: 300}}
            onClick={() => setShow(!show)}
          />
        ) : (
          <IconWrapper
            iconName={iconName}
            className={iconClass}
            styling={iconStyle}
            onClick={() => setShow(!show)}
          />
        )}

        {show && (
          <div
            className={styles.dropdownContent}
            style={{ minWidth: minWidth, ...style }}
            //onMouseLeave={() => setShow(!show)}
          >
            {dropdownContent.map((el, index) => (
              <button
                onClick={el.action}
                key={index}
                className={el?.invisible ? "d-none" : "d-block"}
                type="button"
              >
                {el.name}
              </button>
            ))}
            {children}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
