import React from "react";
import style from "./style.module.scss";
import IconHelper from "../IconMaterial";
import PropTypes from "prop-types";

const Button = React.forwardRef(
  (
    {
      btnText,
      onClick,
      iconName,
      type,
      variant,
      size,
      styles,
      disabled,
      btnClass,
      iconClass,
      iconVariant
    },
    ref
  ) => {
    const className = `${style.btn} ${style[variant]} ${btnClass} ${style[size]} ${
      disabled ? style.btnDisabled : null
    } `;
    return (
      <button
        className={className}
        type={type ?? "button"}
        onClick={disabled ? null : onClick}
        disabled={disabled}
        ref={ref}
        style={styles}
      >
        {iconName ? (
          <IconHelper
            size={24}
            iconName={iconName}
            variant={iconVariant}
            className={`${style.icon} ${iconClass}`}
          />
        ) : null}
        <span>{btnText}</span>
        {variant == "activeFile" ? (
          <IconHelper
            iconName={"keyboard_arrow_down"}
            className={`${style.icon}`}
          />
        ) : null}
      </button>
    );
  }
);

Button.propTypes = {
  variant: PropTypes.oneOf([
    "bg-none",
    "bg-blue",
    "bg-white",
    "bg-outline",
    "activeFile",
    "file",
  ]),
  size: PropTypes.oneOf(["none", "small", "medium", "large"]),
};

Button.defaultProps = {
  variant: "bg-white",
  size: "medium",
};

export { Button };
