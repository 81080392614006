import style from "../../styles/reusable.module.scss";
import { BeatLoader } from "react-spinners";

export default function IconWrapper({
  iconPrefix,
  iconName,
  styling,
  onClick,
  loading,
  className,
  color,
  variant = "outlined",
  isFilled=false,
  isActive = false,
  iconSize="",
  size="md",
  ...props
}) {
  return (
    <button
      className={`${style.iconWrapper} ${className} ${isActive && "active"} ${size==="lg" && style.large}`}
      style={styling}
      onClick={onClick}
      type="button"
      size={size}
      {...props}
    >
      {loading ? (
        <BeatLoader size={4}  color="var(--color-blue)"  />
      ) : (
        variant === "outlined" ?
        <span className={"material-symbols-outlined" + " " + (isFilled ? "material-symbols-filled" : "")} style={{fontSize: iconSize}}>{iconName}</span> :
        <span className="material-symbols-rounded material-symbols-filled" style={{fontSize: iconSize}}>{iconName}</span>
      )}
    </button>
  );
}
