import { useState, useRef, useEffect } from "react";
import { useRouter } from "next/router";
import IconHelper from "../IconMaterial";
import IconWrapper from "../Icons";
import style from "./style.module.scss";
import { Button } from "./Buttons";
import Chip from "../chip";
import InfoModal from "../InfoModal";


export default function FormWrapper({
  title,
  children,
  onSubmit,
  label,
  multi,
  submitText,
  disabled,
  iconBack = true,
  mdTitle,
  mdText,
  components = [],
}) {
  const router = useRouter();
  const [count, setCount] = useState(0);
  const formRef = useRef(null);
  const [closeForm, setCloseForm] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  
  useEffect(() => {
    if (formRef.current) formRef.current.scrollIntoView({ behavior: "smooth" });
  }, [count]);

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
     // setCloseForm(true)
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {closeForm && (
        <InfoModal
          action={() => router.back()}
          closeModal={() => setCloseForm(!closeForm)}
          info={mdTitle ?? "Ohne Speichern schließen"}
          extra={
            mdText ??
            "Wollen Sie die Änderungen verwerfen und die Seite schließen?"
          }
        />
      )}
      <form className={style.formContainer} ref={formRef}>
        <div className={style.header}>
          <button
            className={style.title}
            onClick={() => setCloseForm(!closeForm)}
            type="button"
          >
            {iconBack && <IconHelper iconName={"chevron_left"} />}
            {title}
            <div style={{ marginLeft: "24px" }}>
              {label && <Chip label={label} />}
            </div>
          </button>
          <div className={style.actions}>
            <IconWrapper
              iconName={"close"}
              onClick={() => setCloseForm(!closeForm)}
            />
          </div>
        </div>
        <div className={style.content}>
          {multi ? components[count] : children}
        </div>
        {multi ? (
          <div className={style.navButtons}>
            {count !== 0 && (
              <Button
                variant="bg-white"
                btnText="Zurück"
                onClick={() => setCount(count - 1)}
              />
            )}
            {count < components.length - 1 ? (
              <Button
                btnText={"Weiter"}
                variant="bg-blue"
                size={"large"}
                type="button"
                onClick={() => setCount(count + 1)}
              />
            ) : (
              <Button
                btnText={"Speichern"}
                variant="bg-blue"
                size={"large"}
                disabled={disabled}
                onClick={onSubmit}
              />
            )}
          </div>
        ) : (
          <div className={style.saveBtn}>
            <Button
              btnText={submitText ?? "Speichern"}
              variant="bg-blue"
              size={"large"}
              disabled={disabled}
              onClick={onSubmit}
            />
          </div>
        )}
      </form>
    </>
  );
}
