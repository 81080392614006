import style from "./style.module.scss";
import IconHelper from "../IconMaterial";
import { useState } from "react";

export default function Counter({ name, setValues, values = {}, ...props }) {
  const [active, setActive] = useState(false);
  let val = values[name]
  
  const addNumbers = () => {
    setValues({ ...values, [name]: val ? val + 1  : 1});
  };
  const delNumbers = () => {
    setValues((prev) => {
      return {
        ...prev,
        [name]: val ? val- 1 : 1,
      };
    });
  };

  const onChange = (e) => {
    setValues((prev) => {
      return {
        ...prev,
        [name]: parseInt(e.target.value),
      };
    });
  };

  return (
    <button className={style.countRow} type="button">
      <button
        className={style.countBtn}
        onClick={delNumbers}
        type="button"
        disabled={val < 1}
      >
        <IconHelper iconName={"remove"} />
      </button>
      <input
        id={name}
        className={style.count}
        value={parseInt(val)}
        name={name}
        type={"number"}
        onChange={(e) => onChange(e)}
        placeholder={0}
        {...props}
      />
      <button className={style.countBtn} onClick={addNumbers} type="button">
        <IconHelper iconName={"add"} />
      </button>
    </button>
  );
}
