import { useEffect, useState } from "react";
import style from "./style.module.scss";
import { LabelCheckbox } from "./CustomCheckbox";
import SearchBar from "./SearchBar";
import { Button } from "./Buttons";

export default function SelectCheckboxes({
  options = [],
  title,
  name,
  values = {},
  setValues,
  withSearch,
}) {
  const [query, setQuery] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [sortedItems, setSortedItems] = useState([]);


  const filteredItems = options.filter((item) =>
    item.toLowerCase().includes(query.toLowerCase())
  );


  useEffect(() => {
    const selectedItems = filteredItems.filter((item) =>
      values[name]?.includes(item)
    );
    const unselectedItems = filteredItems.filter(
      (item) => !values[name]?.includes(item)
    );


    setSortedItems([...selectedItems, ...unselectedItems]);
  }, [query, values[name], options]);

  const displayedItems = showAll ? sortedItems : sortedItems.slice(0, 10);


  const handleChange = (value, isChecked) => {
    setValues((prev) => {
      const currArr = prev[name] || [];
      if (isChecked) {
        return { ...prev, [name]: [value, ...currArr] };
      } else {
        return {
          ...prev,
          [name]: currArr.filter((str) => str !== value),
        };
      }
    });
  };

  return (
    <div className={style.section}>
      <h3 className={style.sectionTitle}>{title}</h3>
      <div className={`input-lg ${style.formGroup}`}>
        {withSearch && (
          <SearchBar
            value={query}
            name="search"
            onChange={(e) => setQuery(e.target.value)}
          />
        )}

        <div className={style.checkWrapper}>
          {displayedItems.map((el) => (
            <LabelCheckbox
              key={el} 
              value={el}
              checkedVal={values[name]?.includes(el)}
              name={name}
              onChange={(isChecked) => handleChange(el, isChecked)}
            />
          ))}
        </div>
        {options.length > 10 && query.length === 0 ? (
          <Button
            btnText={showAll ? "weniger" : "mehr..."}
            variant="bg-none"
            onClick={() => setShowAll(!showAll)}
          />
        ) : null}
      </div>
    </div>
  );
}

