import { useEffect, useState, useRef } from "react";
import style from "./style.module.scss";
import axios from "axios";
import useApiLink from "../ApiLink";
import { BeatLoader } from "react-spinners";
import { useDetails } from "../CustomHooks";
import Dropdown from "../Dropdown";
import { Button } from "./Buttons";
import { ClickAwayListener } from "@mui/base";

export function FileInput({
  id,
  value,
  defaultIcon,
  uploadedIcon,
  buttonText,
  handleChange,
  type = "bild",
  btnStyle,
  round
}) {
  const [uploading, setUploading] = useState(false);
  const [userDetails] = useDetails();
  const [show, setShow] = useState(false);
  const ApiLink = useApiLink();
  const fileInputRef = useRef(null);
  const btnRef = useRef();

  function triggerFileInput(id) {
    document.getElementById(id).click();
  }

  function handleFileUpload(files) {
    setUploading(true);
    const data = new FormData();
    data.append("file", files[0]);
    axios
      .post(`${ApiLink}/media/upload-file`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userDetails.token}`,
        },
      })
      .then((res) => {
        setUploading(false);
        handleChange(res.data);
      })
      .catch((err) => {
        setUploading(false);
        console.error(err.response);
      });
  }

  const IconName = () => {
    if (uploading) return "sync";
    if (value) return uploadedIcon ?? "description";
    return defaultIcon ?? "upload";
  };

  const dropdownContent = [
    type === "doc"
      ? {
          name: "anzeigen",
          action: () => window.open(value, "_blank"),
        }
      : null,
    {
      name: "Ersetzen",
      action: () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      },
    },
    {
      name: "Löschen",
      action: () => {
        handleChange("");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      },
    },
  ].filter(Boolean);

  return (
    <div className={"position-relative"}>
      <input
        type="file"
        hidden
        id={id}
        onChange={(e) => handleFileUpload(e.target.files)}
        ref={fileInputRef}
        accept={type == "bild" && "image/*"}
      />
      {!value ? (
        <Button
          type="button"
          iconClass={uploading ? "animate-upload" : ""}
          iconName={IconName()}
          onClick={() => triggerFileInput(id)}
          ref={btnRef}
          styles={btnStyle}
          btnText={buttonText ? buttonText : "Bild"}
        />
      ) : (
        <ClickAwayListener onClickAway={() => setShow(false)}>
          <>
            <Dropdown
              type="button"
              iconClass={uploading ? "animate-upload" : ""}
              iconName={IconName()}
              btnText={buttonText ? buttonText : "Bild"}
              dropdownContent={dropdownContent}
              variant={value ? "activeFile" : "file"}
            />
          </>
        </ClickAwayListener>
      )}
    </div>
  );
}

export default function UploadImage({
  value,
  id,
  handleChange,
  styles,
  btnOut,
  round,
  containerWidth,
  imgHeight,
  noBorderBottom,
  ...props
}) {

  return (
    <div
      className={style.imgUploadWrapper}
      style={{ width: containerWidth ?? "100%" }}
    >
      <div
        className={`${style.uploadImg} `}
        style={{
          height: imgHeight ?? "197px",
          borderRadius: noBorderBottom
            ? "20px 20px 0px 0px"
            : round
            ? "50%"
            : "20px",
        }}
      >
        {value && (
          <img
            src={value}
            alt="..."
            width="100%"
            height="100%"
            style={{
              borderRadius: noBorderBottom
                ? "20px 20px 0px 0px"
                : round
                ? "50%"
                : "20px",
            }}
          />
        )}
      </div>
      <div className={btnOut ? style.btnOut : style.drop}>
        <FileInput
          value={value}
          id={id}
          handleChange={handleChange}
          uploadedIcon={"image"}
          round={round}
          btnStyle={{width:  round ?"117.9px": null}}
          {...props}
        />
      </div>
    </div>
  );
}
