import { forwardRef, useRef, useEffect } from "react";
import style from "./style.module.scss";
import { Button } from "./Buttons";
import { NumericFormat } from "react-number-format";
import IconWrapper from "../Icons";

const FloatingLabelInput = forwardRef(({ ...props }, ref) => {
  return (
    <div className={style.floatingLabel}>
      <input
        {...props}
        ref={ref}
        className={props.value ? style.notEmpty : ""}
      />
      <label>{props.label}</label>
    </div>
  );
});

function FloatingLabelTextarea({
  name,
  value = "",
  wordLimit = 150,
  onChange,
  ...props
}) {
  const wordCount = value
    .trim()
    .split(/\s+/)
    .filter((word) => word.length > 0).length;

  const handleChange = (e) => {
    const inputText = e.target.value;
    const words = inputText.trim().split(/\s+/);

    if (words.filter((word) => word.length > 0).length <= wordLimit) {
      onChange(e);
    }
  };

  return (
    <div>
      <div className={style.floatingLabel}>
        <textarea
          name={name}
          value={value}
          onChange={handleChange}
          {...props}
          className={value ? style.notEmpty : ""}
          style={props.style}
        />
        <label>{props.label}</label>
      </div>
      <p className="text-right text-dark-gray">
        {wordCount}/{wordLimit}
      </p>
    </div>
  );
}

function AddNewInput({
  options = [""],
  title,
  label,
  field,
  values,
  setValues,
  btnText,
}) {
  const inputRefs = useRef([]);

  const handleChange = (value, index) => {
    const updatedItem = options?.map((item, i) => (i === index ? value : item));
    setValues({ ...values, [field]: updatedItem });
  };

  const addItem = () => {
    setValues({
      ...values,
      [field]: [...values[field], ""],
    });
  };

  const handleKeyDown = (e, index) => {
    if (e.key == "Enter") {
      e.preventDefault();
      if (index < options.length - 1) {
        console.log("here", index + 1);
        inputRefs.current[index + 1]?.focus();
      } else {
        addItem();
        setTimeout(() => {
          inputRefs.current[index + 1]?.focus();
        }, 0);
      }
    }
  };

  const handleDelete = (index) => {
    const updatedItem = options.filter((_, i) => i !== index);
    setValues({ ...values, [field]: updatedItem });
  };

  return (
    <div className={style.section}>
      <h3 className={style.sectionTitle}>{title}</h3>
      <div className={style.formGroup}>
        {options?.map((el, index) => (
          <div className="d-flex gap-24 align-items-center">
            <div className="input-md" key={index}>
              <FloatingLabelInput
                label={label}
                value={el}
                ref={(el) => (inputRefs.current[index] = el)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onChange={(e) => handleChange(e.target.value, index)}
              />
            </div>
            {index !== 0 && (
              <IconWrapper
                iconName={"delete"}
                onClick={() => handleDelete(index)}
              />
            )}
          </div>
        ))}
        {btnText && (
          <Button
            variant="bg-none"
            iconName="add"
            btnText={btnText}
            onClick={addItem}
            disabled={!options[0]}
          />
        )}
      </div>
    </div>
  );
}

function PricingInput({ name, value, onChange, onKeyDown, id }) {
  return (
    <div className={style.pricingInput}>
      <NumericFormat
        id={id}
        decimalSeparator={","}
        thousandSeparator={"."}
        placeholder={"0"}
        value={value}
        name={name}
        onKeyDown={onKeyDown}
        onValueChange={(e) => onChange(e.value)}
      />
      <p className={style.currency}>€</p>
    </div>
  );
}
export { FloatingLabelInput, FloatingLabelTextarea, AddNewInput, PricingInput };
