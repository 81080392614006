import { useRef, useState, useEffect } from "react";
import style from "./style.module.scss";
import { ClickAwayListener } from "@mui/base";
import IconHelper from "../IconMaterial";
import SearchBar from "./SearchBar";
import { BeatLoader } from "react-spinners";
import CustomCheckbox from "./CustomCheckbox";

function SingleSelect({
  selected,
  setSelected,
  label,
  name,
  position,
  options = [],
  dropdownStyles,
  fs = "reg",
  error,
  size,
  search,
  formatter,
  loading,
  emptyOptionText,
  onChange,
  extraClassname = "",
  disabled,
  selectKey, //when options are objects that have a specific key we want as the selected option
}) {
  const [open, setOpen] = useState(false);
  const btnRef = useRef();
  const [query, setQuery] = useState("");
  const [selectedObj, setSelectedObj] = useState(
    selectKey && options?.find((x) => x?.[selectKey] === selected)
  );
  const [listMinWidth, setListMinWidth] = useState(0);

  useEffect(() => {
    if (btnRef.current) {
      setListMinWidth(btnRef.current.getBoundingClientRect().width);
    }
  }, [selected, btnRef.current]);

  const filteredOptions = formatter
    ? options
    : options.filter((el) => el?.toLowerCase()?.includes(query.toLowerCase()));

  const a = {
    minWidth: listMinWidth,
    ...dropdownStyles,
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={style.singleSelect + " " + extraClassname}>
        <button
          onClick={() => setOpen(!open)}
          className={`${style.selectBtn} ${open ? style.active : null} ${
            error ? style.error : null
          } 
          ${size === "sm" ? style.size_sm : null} 
          ${size === "md" ? style.size_md : null} 
          ${size === "lg" ? style.size_lg : null}`}
          type="button"
          ref={btnRef}
          disabled={disabled}
        >
          {selected ? (
            <span>
              {formatter
                ? formatter(selectKey ? options?.find((x) => x?.[selectKey] === selected) : selected)
                : (selectKey
                ? options?.find((x) => x?.[selectKey] === selected)
                : selected)}
            </span>
          ) : (
            <span className="text-grey"> {label}</span> || "Select"
          )}
          <IconHelper
            iconName={"keyboard_arrow_down"}
            className={style.chevron}
          />
        </button>
        {open &&
          (loading ? (
            <BeatLoader color="#fff" size={10} />
          ) : (
            <div
              className={`${style.dropdownContentContainer} ${
                size === "sm" ? style.size_sm : null
              } 
              ${size === "md" ? style.size_md : null} 
              ${size === "lg" ? style.size_lg : null}`}
              style={a}
            >
              <div className={style.dropdownContent}>
                {search && (
                  <SearchBar
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                )}
                {emptyOptionText && (options && options.length === 0) && (
                  <p className={style.emptyOptionText}>{emptyOptionText}</p>
                )}
                {filteredOptions &&
                  filteredOptions.map((el, index) => (
                    <button
                      key={index}
                      onClick={() => {
                        setSelected
                          ? setSelected(selectKey ? el?.[selectKey] : el)
                          : null;
                        onChange
                          ? onChange(selectKey ? el?.[selectKey] : el, name)
                          : null; //refactoring
                        selectKey && setSelectedObj(el);
                        setOpen(false);
                      }}
                      type="button"
                    >
                      {formatter ? formatter(el) : el}
                    </button>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </ClickAwayListener>
  );
}

export function MultiSelect({
  options,
  placeholder,
  selected,
  setSelected,
  label,
  isMulti,
  small,
  filterPlaceholder,
}) {
  const [show, setShow] = useState(false);
  const [filterText, setFilterText] = useState("");

  const toggleDropdown = () => setShow(!show);

  const handleSelect = (option) => {
    if (selected.includes(option)) {
      setSelected(selected.filter((item) => item !== option));
    } else {
      setSelected([...selected, option]);
    }
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div className={style.selectWrapper}>
        <div className={style.selectBtn} onClick={toggleDropdown}>
          <div className={style.selectedItemChip}>
            {(!selected || selected.length === 0) && label}
            {selected &&
              selected.map((item, index) => (
                <p key={index} className={style.selectedItem}>
                  {item}
                  {index < selected.length - 1 && ", "}
                </p>
              ))}
          </div>
          <IconHelper
            iconName="keyboard_arrow_down"
            className={style.chevron}
          />
        </div>

        {show && (
          <div className={style.selectList}>
            <div className={style.selectInputWrapper}>
              <input
                type="text"
                placeholder={filterPlaceholder || "Filter options..."}
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className={style.selectInput}
                autoFocus
              />
            </div>
            {filteredOptions.map((option, index) => (
              <CustomCheckbox
                key={index}
                checked={selected.includes(option)}
                value={option}
                label={option}
                extraClassName={style.selectOption}
                onChange={() => handleSelect(option)}
              />
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

export default SingleSelect;
